
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  criar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",

      width: "33em !important",
      maxWidth: "40em !important",
    },
  },
  Titulo: {
    fontWeight: 700,
    paddingBottom: "20px",
    color: "#555",
    paddingTop: 30,
  },
  Subtitulo: {
    fontWeight: "normal",
    color: "#777",
    marginBottom: 20,
  },
  Item: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "20px",
  },
  ContainerVerification: {
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '.2rem',
    gap: '7px',
    height: '54px',
    width: '311px',
   
  },
  VerificationCaracter: {
    border: '1px solid #222',
    fontSize: '20px',
    borderRadius: '12px',
    color: '#eee',
    backgroundColor:'#fff',
    boxShadow: 'none',
    cursor: 'pointer !important',
    transition: 'all 400ms ease-in-out',
  },
  VerificationCaracterInac: {
    opacity: .5,
  },
  VerificationCaracterSelec: {
    border: 'none !important',
    outline: 'none',
    boxShadow: `1px 1px 6px  #222`,
    opacity: 1,
  },
  VerificationCaracterFil: {
color:'black',
  },
}));

export default useStyles;