import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Select } from "@mui/material";

export default function Seletor({
  label,
  size,
  fullWidth,
  variant,
  value,
  list,
  variantTextField,
  className,
  setSelect = null,
  setSelectIdDesc = null,
  disabled,
  sx,
  isLoading = false,
  key,
}) {
  const [items, setItems] = useState(null);
  const [defaultItem, setDefaultItem] = useState(null);

  const handleChange = (newValue) => {
    if (setSelectIdDesc) {
      if (newValue.hasOwnProperty("label"))
        setSelectIdDesc(newValue.id, newValue.label);
      else if (newValue.hasOwnProperty("descricao"))
        setSelectIdDesc(newValue.id, newValue.descricao);
      else setSelectIdDesc(newValue.id);
    }

    if (setSelect) {
      setSelect(newValue.id);
    }
  };

  const carregarDados = (dados, valor) => {
    let data = [];

    for (let i = 0; i < dados.length; i++) {
      const element = dados[i];

      const newItem = {
        label: element.descricao,
        id: element.id,
      };

      data.push(newItem);

      if (valor > 0 && element.id === valor) {
        setDefaultItem(newItem);
      }
    }

    setItems(data);
  };

  useEffect(() => {
    carregarDados(list, value);
  }, [list]);

  return (
    <>
      <FormControl variant={variant} size="large" sx={sx} className={className} fullWidth={fullWidth}>
        {isLoading && (
          <Skeleton variant="wave" height={40} key={key} animation={false} />
        )}
        {!isLoading && !defaultItem && items?.length > 0 && (
          <Autocomplete
            disablePortal
            disabled={disabled}

            id={`id-${label}`}
            options={items}
            renderInput={(params) => (
              <TextField

                color="primary"
                {...params}
                label={label}
                variant={variantTextField}
              />
            )}
            onChange={(event, newValue) => handleChange(newValue)}
            size={size ?? "large"}
          />
        )}
        {!isLoading && defaultItem && (
          <Autocomplete
            disablePortal
            disabled={disabled}
            id={`id-${label}`}
            options={items}
            renderInput={(params) => (
              <TextField
                focused
                variant={variantTextField}
                {...params}
                label={label}
              />
            )}
            onChange={(event, newValue) => handleChange(newValue)}
            size={size ?? "large"}
            defaultValue={defaultItem}
          />
        )}
      </FormControl>
    </>
  );
}
