import { useEffect, useRef, useState } from "react";
import BandeiraBrasil from "../../../../assets/images/bandeiras/brasil.png";
import BarraDeOuro from '../../../../assets/images/formando.jpg';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowLeft from '@mui/icons-material/ArrowBackIos';
import ArrowRigth from '@mui/icons-material/ArrowForwardIos';
import {
      Grid,
      Box,
      Typography,
      FormControl,
      useTheme,
      Link,
      Skeleton,
      Alert,
      Card,
      CardContent,
      CardActions,
} from "@mui/material";

import { Info as InfoIcon } from "@mui/icons-material";

import {
      ButtonAcaoGrande,
      Termo,
      Seletor,
      CampoOperacao,
      TooltipInformacao,
      PositionTooltip,
      CampoImg,
      ListItemVitrineSelecionado,
      Modal,
      Sedex,
} from "../../..";

import SeletorMoeda from "../../../Campos/SeletorMoeda";

import { Style } from "./styles";

import useAlert from "../../../../hooks/useAlert/index";

import {
      formatMoneyInString,
      formatMoneyInStringForm,
      verificaPedidoDiferente,
      verificaPedidoTiposIguais,
} from "../../../../util/helpers";

import { useAuth } from "../../../../hooks/AuthContext";

import { useMutation, useQuery } from "react-query";

import usePedido from "../../../../hooks/usePedido";
import { GETTERMORESPONSABILIDADE } from "../../../../util/typesReactQuery";
import { getTermoResponsabilidade } from "../../../../api/TermoResponsabilidade";
import ButtonAcaoGrandeOutlined from "../../../Botoes/ButtonAcaoGrande copy";
import { Carousel, Panel } from "rsuite";
import TypesModal from "../../../../util/TypesModalCarrinho";
import CampoNumber from "../../../Campos/CampoNumber";
import CampoTipoNumerico from "../../../Campos/CampoTipoNumerico";

const Item = styled(Paper)(({ theme }) => ({
      //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      boxShadow: "none",
      padding: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
}));

const imageCarousel = [
      { src: BarraDeOuro, id: 1 },
      { src: BarraDeOuro, id: 2 },
      { src: BarraDeOuro, id: 3 },
]

export function VitrineBaseImageOuro({
      vitrine,
      itemSelected,
      onChangeItem,
      onChangeValorMe,
      onAddCart,
      itemCarrinho,
      isErrorTermo = false,
      isErrorTermoResp = false,
      onChangePraca,
      onChangeTermo,
      isTermoResponsabilidadeAceite,
      isTermoUsoAceite,
      onChangeTermoRespon,
      loadingButton = false,
      setLoadingButton,
      disabledButton,
      onBlurValueChangeItem,
}) {
      const classes = Style();
      const theme = useTheme();
      const { showAlertInfo, showAlertError, showAlertSuccess } = useAlert();
      const { user } = useAuth();
      const { itensPedido: listItemCarrinho } = usePedido();
      const [modalShow, setModalShow] = useState(false);
      const [typeModal, setTypeModal] = useState(TypesModal.adicionaItem);
      const [modalMessage, setModalMessage] = useState("");
      const [termoResponsabilidade, setTermoResponsabilidade] = useState("");
      const [activeIndexCarousel, setActiveIndexCarousel] = useState(0);

      const handlerTermoAceite = (aceito) => {
            onChangeTermo(aceito);
      };

      const handlerTermoResponsabilidade = (aceito) => {
            onChangeTermoRespon(aceito);
      };

      const handlerVerificaPossuiPedidoTiposIguais = (event) => {
            let text = verificaPedidoTiposIguais(itemCarrinho, listItemCarrinho);

            if (!text) onAddCart(event, true);

            setTypeModal(() => TypesModal.continuePedido);
            setModalMessage(() => text);
            setModalShow(() => true);
      };

      const handleManipulationEventAdd = (event) => {
            let text = verificaPedidoDiferente(itemCarrinho, listItemCarrinho);

            if (!!text) {
                  setTypeModal(() => TypesModal.adicionaItem);
                  setModalMessage(text);
                  setModalShow(true);
                  return;
            }

            onAddCart(event, false);
      };

      useQuery([GETTERMORESPONSABILIDADE], () => getTermoResponsabilidade(), {
            onSuccess: (data) => {
                  if (!data) {
                        showAlertError(
                              `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum termo responsabilidade - RADLH`
                        );
                        return;
                  }
                  if (data.errorMessage) {
                        showAlertError(
                              `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - R41HL`
                        );
                        return;
                  }

                  setTermoResponsabilidade(data.data);
            },
            onError: (error) => {
                  showAlertError(
                        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RDXS2`
                  );
            },
            refetchOnWindowFocus: false,
      });

      const handlerModalOptions = (res) => (event) => {
            if (res === "sim") {
                  setLoadingButton(true);
                  setTimeout(() => {
                        if (typeModal === TypesModal.adicionaItem) onAddCart(event, false);
                        else onAddCart(event, true);

                        setLoadingButton(false);
                  }, 2000);
            }

            setModalShow(false);
      };

      const validaOperacoes = () => {
            if (listItemCarrinho) {
                  if (
                        listItemCarrinho?.itens.length > 0 &&
                        listItemCarrinho?.praca &&
                        listItemCarrinho.praca.id == itemCarrinho?.idPraca &&
                        listItemCarrinho?.itens?.some(
                              (i) => i?.idTipoItemCarrinho == itemCarrinho?.idTipoItemCarrinho
                        )
                  )
                        return true;
                  else return false;
            }

            return false;
      };

      function isHabilitaSedex() {
            let pracaSelecionada = vitrine?.pracas?.find(
                  (item) => item.id === itemCarrinho.idPraca
            );
            if (!pracaSelecionada?.dadosSedex?.habilitaEntregaSedexOuro) {
                  return false;
            }
            return true;
      }


      function handlerSelecte(value) {
            setActiveIndexCarousel(value);
      }

      function handlerBackIndex() {
            if (activeIndexCarousel > 0)
                  setActiveIndexCarousel((prevState) => prevState - 1);
            if (activeIndexCarousel === 0)
                  setActiveIndexCarousel(() => imageCarousel.length - 1);
      }

      function handlerNextIndex() {
            if (imageCarousel.length > activeIndexCarousel + 1)
                  setActiveIndexCarousel((prevState) => prevState + 1);
      }

      return (
            <>
                  <Modal
                        show={modalShow}
                        close={() => setModalShow(false)}
                        sx={{ border: "" }}
                  >
                        <ErrorOutlineIcon
                              color="primary"
                              fontSize="large"
                              sx={{ margin: "auto", width: "100%" }}
                        />
                        <Typography
                              sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    gap: ".3rem",
                                    textAlign: "center",
                                    mb: 3,
                              }}
                        >
                              {modalMessage}
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                              <ButtonAcaoGrande
                                    sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
                                    color="success"
                                    texto="Sim"
                                    loading={loadingButton}
                                    Handle={handlerModalOptions("sim")}
                              />
                              <ButtonAcaoGrandeOutlined
                                    sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
                                    color="error"
                                    texto="não"
                                    variant="outlined"
                                    loading={loadingButton}
                                    Handle={handlerModalOptions("não")}
                              />
                        </Box>
                  </Modal>
                  <Box className={classes.Carrinho} >
                        <Grid
                              container
                              justifyContent={'center'}
                              sx={{
                                    mt: 0,
                                    alignItems: "center",
                                    display: "flex",
                                    gap: '2rem'
                              }}
                        >
                              <Grid item md={5} lg={5}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                          <Grid item md={8} lg={8} sx={{ mt: 3, mb: 1 }}>
                                                <Item>
                                                      <FormControl className={classes.FormControl} size="small">
                                                            {vitrine && vitrine.pracas ? (
                                                                  <Seletor
                                                                        setSelect={onChangePraca}
                                                                        list={vitrine.pracas}
                                                                        size="normal"
                                                                        label="Qual sua região?"
                                                                        variant="outlined"
                                                                        fullWidth={true}
                                                                        value={itemCarrinho.idPraca}
                                                                  />
                                                            ) : (
                                                                  <Skeleton width="auto" height={50} animation="wave" />
                                                            )}
                                                      </FormControl>
                                                </Item>
                                          </Grid>
                                          <Grid item md={8} lg={8}>
                                                <Item sx={{ display: "flex", mb: 1 }}>
                                                      {vitrine &&
                                                            vitrine.itensVitrine &&
                                                            vitrine.itensVitrine.length > 0 && (
                                                                  <FormControl sx={{ minWidth: "100%" }}>
                                                                        <SeletorMoeda
                                                                              list={vitrine.itensVitrine}
                                                                              value={itemCarrinho.idItemVitrine}
                                                                              setSelect={onChangeItem}
                                                                              size="normal"
                                                                              label="Selecione a Barra"
                                                                              fullWidth={true}
                                                                              isOuro
                                                                        />
                                                                  </FormControl>
                                                            )}
                                                </Item>
                                          </Grid>
                                          <Grid item md={8} lg={8}>
                                                <Item sx={{ mb: 1 }}>
                                                      <CampoTipoNumerico
                                                            autoFocus={true}
                                                            className={classes.TextField}
                                                            casasDecimais={2}
                                                            prefix={""}
                                                            size="normal"
                                                            fullwidth
                                                            label="Quantidade de barras"
                                                            decimalSeparator={false}
                                                            enable={true}
                                                            variant="outlined"
                                                            value={parseInt(itemSelected.valorMe)}
                                                            onChange={onChangeValorMe}
                                                      />
                                                </Item>
                                          </Grid>
                                          <Grid md={8} lg={8} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                                                {" "}
                                                <Typography className={classes.dFlex} variant="body1" sx={{ fontSize: '14px' }}>
                                                      <TooltipInformacao
                                                            sx={{ marginRight: 1, marginLeft: 2 }}
                                                            positionStart={PositionTooltip.left}
                                                            descricao="O valor Informado, corresponde a 1 unidade da respectiva barra selecionada, sua operação será calculada com base nesse valor apresentado, podendo variar de acordo com o tipo ou tamanho selecionado."
                                                      >
                                                            <InfoIcon
                                                                  sx={{ fontSize: 20, mr: 1 }}
                                                                  color="primary"
                                                            ></InfoIcon>
                                                      </TooltipInformacao>{" "}
                                                      Preço do {itemCarrinho?.moeda?.descricao}  =&nbsp;<span style={{ fontWeight: 'bold' }}>R$ {formatMoneyInString(itemCarrinho.valorVet)}</span>

                                                </Typography>
                                                <span style={{ fontSize: '12px', opacity: .7, marginLeft: '1.8rem' }}>Cotação do dia</span>
                                          </Grid>

                                          <Grid item md={8} lg={8}>
                                                <Item sx={{ mb: 1 }}>
                                                      <Card variant="outlined">
                                                            <CardContent sx={{ paddingBottom: '16px !important' }}>
                                                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                                        <Typography>
                                                                              R$ {formatMoneyInStringForm(itemCarrinho.valorTotalMN)}
                                                                        </Typography>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                              <Box component="img" sx={{ width: 20, mr: 1 }} src={BandeiraBrasil} />
                                                                              <Typography>
                                                                                    BRL
                                                                              </Typography>
                                                                        </Box>

                                                                  </Box>
                                                            </CardContent>
                                                      </Card>
                                                </Item>
                                          </Grid>




                                          {isHabilitaSedex() && (
                                                <Grid sx={{ mt: 2, mb: 1 }} item md={8} lg={8}>
                                                      <Panel header="Simule uma entrega via sedex" collapsible bordered>
                                                            <Sedex
                                                                  idPraca={itemCarrinho.idPraca}
                                                                  isOuro={true}
                                                                  valorTotalMN={itemCarrinho.valorTotalMN}
                                                            />
                                                      </Panel>
                                                </Grid>
                                          )}

                                          <Grid item lg={8} md={8}>
                                                <ButtonAcaoGrandeOutlined
                                                      sx={{ zIndex: theme.zIndex.mobileStepper }}
                                                      loading={loadingButton}
                                                      variant="contained"
                                                      Handle={handleManipulationEventAdd}
                                                >
                                                      {" "}
                                                      + Adicionar Moeda no carrinho
                                                </ButtonAcaoGrandeOutlined>
                                          </Grid>
                                    </Grid>
                              </Grid>
                              <Grid item md={5} lg={5} >
                                    <Box onClick={handlerBackIndex}>
                                          <ArrowLeft />
                                    </Box>
                                    <Box>
                                          <Carousel style={{ width: '24.44rem', height: '37rem' }}
                                                shape='bar' autoplay={false} activeIndex={activeIndexCarousel}
                                                onSelect={handlerSelecte}
                                          >
                                                {imageCarousel.map((item, index) => (
                                                      <>
                                                            <img src={item.src} key={item.id} />
                                                      </>
                                                ))}
                                          </Carousel>
                                    </Box>
                                    <Box>
                                          <ArrowRigth onClick={handlerNextIndex} />
                                    </Box>
                              </Grid>
                              <Grid item md={4} lg={4}>
                                    {validaOperacoes() && (
                                          <Grid sx={{ mt: 1, mb: 1, maxWidth: "500px" }}>
                                                <ListItemVitrineSelecionado
                                                      carrinho={listItemCarrinho}
                                                      loadingButton={loadingButton}
                                                      setLoadingButton={setLoadingButton}
                                                      onAddCart={onAddCart}
                                                />

                                                <Grid item xs={12} md={12} lg={12}>
                                                      <Box
                                                            className={
                                                                  isTermoUsoAceite
                                                                        ? [classes.AlertaTermos, classes.isAceite]
                                                                        : isErrorTermo
                                                                              ? [classes.AlertaTermos, classes.isErrorTermo]
                                                                              : classes.AlertaTermos
                                                            }
                                                      >
                                                            <Termo
                                                                  modalText={vitrine?.termoUso}
                                                                  isAceite={isTermoUsoAceite}
                                                                  setIsAceite={handlerTermoAceite}
                                                                  titulo="Termos de Uso"
                                                            >
                                                                  <Typography color="text.secondary" variant="subtitle1">
                                                                        {" "}
                                                                        Ao continuar, você aceita os
                                                                        <Link style={{ cursor: "pointer" }}> Termos de Uso </Link>
                                                                        da nossa empresa brasileira autorizada pelo Banco Central do
                                                                        Brasil.
                                                                  </Typography>
                                                            </Termo>
                                                      </Box>

                                                      <Grid item xs={12} md={12} lg={12}>
                                                            <Box
                                                                  className={
                                                                        isTermoResponsabilidadeAceite
                                                                              ? [classes.AlertaTermos, classes.isAceite]
                                                                              : isErrorTermoResp
                                                                                    ? [classes.AlertaTermos, classes.isErrorTermo]
                                                                                    : classes.AlertaTermos
                                                                  }
                                                            >
                                                                  <Termo
                                                                        modalText={termoResponsabilidade?.conteudoHtml}
                                                                        isAceite={isTermoResponsabilidadeAceite}
                                                                        setIsAceite={handlerTermoResponsabilidade}
                                                                        titulo="Termos de Responsabilidade"
                                                                  >
                                                                        <Typography color="text.secondary" variant="subtitle1">
                                                                              {" "}
                                                                              Ao continuar, você aceita os
                                                                              <Link style={{ cursor: "pointer" }}>
                                                                                    {" "}
                                                                                    Termos de Responsabilidade.{" "}
                                                                              </Link>
                                                                        </Typography>
                                                                  </Termo>
                                                            </Box>
                                                            <br />
                                                      </Grid>

                                                      <Grid sx={{ ml: 2, mr: 2 }}>
                                                            <ButtonAcaoGrande
                                                                  sx={{ zIndex: theme.zIndex.mobileStepper }}
                                                                  loading={loadingButton}
                                                                  variant="contained"
                                                                  Handle={handlerVerificaPossuiPedidoTiposIguais}
                                                            >
                                                                  Continuar pedido
                                                            </ButtonAcaoGrande>
                                                      </Grid>
                                                </Grid>
                                          </Grid>
                                    )}
                              </Grid>
                        </Grid>


                  </Box>
            </>
      );
}
