import api from "../../index";
import { configurationVariables } from "../../../environment/index";

const idOrigem = 3; //LojaCambio;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const addItem = async (
  idCliente,
  idPraca,
  idTipoPedido,
  itemCarrinho
) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/ConfiguraItemCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idPraca=${idPraca}&idTipoPedido=${idTipoPedido}`,
      itemCarrinho
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "5448");
  }
};

export const configuraCartaoAdicional = async (
  idCliente,
  idTipoPedido,
  idItemVitrine,
  emitirAdicional
) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/ConfiguraItemCarrinhoCartaoAdicional?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idTipoPedido=${idTipoPedido}&idItemVitrine=${idItemVitrine}&emitirAdicional=${emitirAdicional}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "5448");
  }
};

export const addNumeroCartaoRecarga = async ({ idCliente, idPraca, idTipoPedido, idMoeda, numeroCartao }) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/AtualizaNumeroCartaoitemCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idPraca=${idPraca}&idTipoPedido=${idTipoPedido}&idMoeda=${idMoeda}&numeroCartao=${numeroCartao}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R0DE6");
  }
};

export const addPagamento = async (idCliente, formaPagamento) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/ConfiguraPagamento?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
      formaPagamento
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
      nextPage: response.nextPage,
      previewPage: response.previewPage,
    };
  } catch (error) {
    throw new Error(error + "57C7");
  }
};

export const getCarrinho = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoTurismo/GetModuloCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "6DE9");
  }
};

export const finalizar = async (idCliente) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/Finalizar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "3388");
  }
};

export const RemoveItemByIdMoeda = async ({ idCliente, idMoeda }) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/RemoverItemByIDMoeda?idCliente=${idCliente}&idOrigem=${idOrigem}&idMoeda=${idMoeda}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + "R773C");
  }
};

export const LimparCarrinho = async ({ idCliente }) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/Limpar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + "R773C");
  }
};

//?=================================== Bloco entrega ================================

export const getEntrega = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoTurismo/GetEntregaModulo?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "6DE9");
  }
};

export const addEntrega = async (idCliente, entrega) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoTurismo/ConfiguraEntrega?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
      entrega
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R57D8");
  }
};

export const getRegioesEntregaByIDPraca = async (
  idPraca,
  isVenda,
  isEspecie
) => {

  try {
    const { data: response } = await api.get(
      `/RegiaoEntrega/GetByIDPraca?idPraca=${idPraca}&isVenda=${isVenda}&isEspecie=${isEspecie || false}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " R009X");
  }
};

export const getPontosRetiradaByIDPraca = async (idPraca) => {
  try {
    const { data: response } = await api.get(
      `/PontoRetirada/GetByIDPraca?idPraca=${idPraca}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " R192X");
  }
};

//?=================================== Bloco Cartão ================================

export const getParametrosCartao = async () => {
  try {
    const { status, data: response } = await api.get(
      "/Cartao/GetParametrosCartao"
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " R28XS");
  }
};


