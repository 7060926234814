import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";
import {getlocalStorageIdHotsite} from '../../util/LocalStorage'

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage,
  Loja: "Broker",
  UserStorage: "c5Gwf2wGx0GEWHSmL6BVHA==",
  UserToken: "9JhXOjAWBU67gMvxCMSnFQ==",
  // UrlApi: "https://hlg-ecommerce-bkb-api.primecase.com.br/api",
  // UrlApi: "https://hlg-ecommerce-par-api.primecase.com.br/api",
  // UrlApi: "https://prd-ecommerce-gop-api.primecase.com.br/api",
  // UrlApi: "https://hlg-ecommerce-bkb-api.primecase.com.br/api",
  UrlApi: "https://localhost:44388/api",
  // UrlApi: "https://prd-ecommerce-und-api.primecase.com.br/api",
  UrlLoja: "https://hlg-ecommerce-bkb-web.primecase.com.br/",
  // UrlLoja:"",
};


export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#8E1B1B",
      light: "#AB5454",
      //lighter: "#ece6f7",
      dark: darkenColor("#8E1B1B", 10), // Escurece em 20%
    },
    secondary: {
      main: "#AC2126",
    },
    error: {
      main: "#AC2126",
      light:"#ea868f",
    },
    success: {
      main:"rgb(46, 125, 50)",
     light:"#75b798"
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
